<template>
  <div class="add-manage">
    <div class="tab">
      <div class="back" @click="goBack()">
        <i class="iconfont icon-fanhui"></i> <span>返回</span>
      </div>
      <div class="active"><i class="iconfont icon-kaoshi"></i> 考试信息</div>
    </div>
    <div class="form">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm"
        label-position="left">
        <el-form-item label="考试名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="考试时间" required>
          <el-col :span="11">
            <el-form-item prop="startDttm">
              <el-date-picker type="datetime" placeholder="开始时间" v-model="ruleForm.startDttm" format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm" style="width: 100%"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2" style="text-align: center">到</el-col>
          <el-col :span="11">
            <el-form-item prop="endDttm">
              <el-date-picker type="datetime" placeholder="结束时间" v-model="ruleForm.endDttm" style="width: 100%"
                format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="交卷时间" prop="delaySubmitTime">
          <el-input v-model.number="ruleForm.delaySubmitTime"></el-input>
          <p style="color: red">考生在正式考试开始{{ ruleForm.delaySubmitTime }}分钟之后才可以交卷。</p>
        </el-form-item>
        <el-form-item label="考试类型" prop="type">
          <el-select v-model="ruleForm.type" placeholder="请选择考试类型" style="width: 100%">
            <el-option label="正式考试" :value="1"></el-option>
            <el-option label="模拟考试" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试试卷" prop="paperId">
          <el-select v-model="ruleForm.paperId" placeholder="请选择考试试卷" style="width: 100%" @change="getPaper">
            <el-option :label="item.name" :value="item.id + ''" v-for="item in examPaper" :key="item.id"></el-option>
          </el-select>
          <span class="red">{{ examInfo }}</span>
        </el-form-item>

        <el-form-item label="题目随机排列" prop="random">
          <el-switch v-model="ruleForm.random"></el-switch>
        </el-form-item>
        <el-form-item label="考试人数" prop="studentCount" v-if="editFlag">
          <span>{{ ruleForm.studentCount }}</span>
        </el-form-item>
        <el-form-item>
          <!-- {{ editFlag ? '提交' : '立即创建' }} -->
          <el-button type="primary" @click="submitForm('ruleForm')" :disabled="isBegin">提交</el-button>
          <p style="color: red" v-if="isBegin">考试进行中,不可修改</p>
        </el-form-item>
      </el-form>
    </div>

    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="400px" center :close-on-click-modal="false">
      <p style="text-align: center"><span>是否立刻导入学员名单?</span></p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goBack()">取 消</el-button>
        <el-button type="primary" @click="onsure()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';



export default {
  name: 'AddExam',
  data() {
    var endPass = (rule, value, callback) => {
      new Date(this.ruleForm.startDttm).getTime() < new Date(value).getTime() ? callback() : callback(new Error('结束时间不能小于开始时间'));
    };
    var validatePass = (rule, value, callback) => {
      if (!value || value == '') {
        callback(new Error('请输入交卷时间'));
      } else {
        if (!(typeof value === 'number')) {
          callback(new Error('请输入数字'));
          return
        }
        callback();
      }
    };
    return {
      ruleForm: {
        name: '',
        paperId: '',
        startDttm: null,
        endDttm: null,
        random: true,
        type: null,
        studentCount: '',
        delaySubmitTime: 30
      },
      rules: {
        name: [{ required: true, message: '请输入考试名称', trigger: 'blur' }],
        delaySubmitTime: [{ required: true, message: '请输入交卷时间', trigger: 'blur' },
        { validator: validatePass, trigger: 'blur' }
        ],
        type: [{ required: true, message: '请选择考试类型', trigger: 'change' }],
        startDttm: [{ type: 'string', required: true, message: '请选择开始时间', trigger: 'change' }],
        endDttm: [
          { type: 'string', required: true, message: '请选择结束时间', trigger: 'change' },
          { validator: endPass, trigger: 'change' }
        ],
        paperId: [{ required: true, message: '请选择考试试卷', trigger: 'change' }],
        random: [{ required: true }],
        number: [{ required: true }],
      },
      examPaper: [],
      centerDialogVisible: false,
      examId: null,
      editFlag: false,
      examInfo: '',
      isBegin: false
    };
  },
  components: {},
  created() {
    this.getExamPaper();


  },
  methods: {
    // 新增考试
    async addExam() {
      let data = {
        id: this.examId ? this.examId : null,
        paperId: this.ruleForm.paperId,
        name: this.ruleForm.name,
        startDttm: moment(this.ruleForm.startDttm).format('YYYY-MM-DD HH:mm:ss'),
        endDttm: moment(this.ruleForm.endDttm).format('YYYY-MM-DD HH:mm:ss'),
        random: this.ruleForm.random ? 1 : 0,
        type: this.ruleForm.type,
        delaySubmitTime: this.ruleForm.delaySubmitTime
      };

      const result = this.editFlag
        ? await this.Api.exammanage.updateExam(data)
        : await this.Api.exammanage.addExam(data);
      if (result.code != 200) {
        this.$message.error(result.message);
        return;
      }
      if (this.editFlag) {
        this.$message.success('更新成功!');
        this.centerDialogVisible = true;
        this.$router.push('/admin/exam-manage');
      } else {
        this.$message.success('新增成功');
        this.examId = result.data.id;
        this.centerDialogVisible = true;
      }
    },
    getPaper(id) {
      let paper = this.examPaper.find(item => item.id == id);
      let item = paper.paperAnalyses['题型题量分析'];
      // 单选题15题，多选题15题，判断题20题
      let text = '该套试卷包括'
      for (let i = 0; i < item.length; i++) {
        text += item[i].name + item[i].count + '题' + (i == item.length - 1 ? '。' : '，');
      }

      this.examInfo = item.length > 0 ? text : '该套试卷暂无题型题量分析';
    },
    async getExam() {
      const result = await this.Api.exammanage.queryExam({ id: this.examId });
      console.log('🚀 ~ getExam ~ result:', result);

      this.ruleForm = {
        name: result.data.name,
        paperId: result.data.paperId,
        startDttm: result.data.startDttm,
        endDttm: result.data.endDttm,
        random: result.data.random == 1 ? true : false,
        type: result.data.type,
        studentCount: result.data.studentCount, //todo 考试人数
        delaySubmitTime: result.data.delaySubmitTime
      };

      this.getPaper(result.data.paperId);
      if (new Date().getTime() > new Date(result.data.startDttm).getTime()) {
        this.isBegin = true
        return;
      }

    },
    onsure() {
      console.log('确定导入学员名单');
      this.centerDialogVisible = false;
      this.$router.push(`/admin/exam-manage/import-student/${this.examId}`);
    },
    goBack() {
      console.log('返回');

      this.centerDialogVisible = false;
      this.$router.push('/admin/exam-manage');
    },
    async getExamPaper() {
      // todo 考试试卷
      const result = await this.Api.exammanage.getPapers({});
      let examId = this.$route.params.examId;
      if (examId != -1) {
      this.examId = examId;
      this.editFlag = true;
      this.getExam();
    }
      this.examPaper = result.data;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addExam();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
};
</script>

<style scoped lang="scss">
.add-manage {
  overflow: hidden;
  width: 100%;

  .tab {
    height: 48px;
    margin: 20px;
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    position: relative;
    margin-left: 100px;

    div {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 30px;
      padding: 0 10px;
      cursor: pointer;
      bottom: -1px;
      box-sizing: border-box;

      i {
        margin-right: 3px;
      }
    }

    .active {
      border-bottom: 3px solid #3bafda;
      color: #3bafda;
    }

    .back {
      position: absolute;
      left: -80px;
      display: flex;
      align-items: center;
    }
  }

  .form {
    width: 50%;
    margin-left: 20px;

    .new-btn {
      width: 120px;
      height: 32px;
      margin: 20px 0 0 20px;
      background: #3498db;
    }
  }
}

::v-deep .el-form-item {
  margin-bottom: 20px;
}

.red {
  color: red;
}

.btn-padding {
  padding: 12px 20px;
}
</style>
